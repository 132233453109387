import { db_type } from "../EndUser/db_config";


let changedBackEndUrl = db_type === 'online' ? '' : '';


export const backendUrl = 'https://tentovision1.cloudjiffy.net';
// export const backendUrl = 'http://10.147.21.212:5009';
// export const backendUrl = 'http://localhost:5008';
// export const backendUrl = 'http://localhost:5008';
// export const backendUrl = 'http://10.147.20.101:5008';
// export const backendUrl = 'https://tentovision1.cloudjiffy.net';
// export const backendUrl = 'http://10.147.21.167:5008';
export const backendUrlLocal = 'http://10.147.21.167:5008';
// export const backendUrlLocal = 'http://10.147.21.167:5008';
export const jetsonIpUrl = 'http://10.147.21.167:5050';
export const face_enroll_check_status = 'http://10.147.21.167:5025';
export const backendUrlWithouttPort = `https://face-ec2.tentovision.com`;
// export const backendUrlWithouttPort = `http://10.147.21.167`;

export const EC2URLTOGETID = 'https://via-ec2.tentovision.com/via';